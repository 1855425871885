import React from 'react';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

// import {
//   DataGrid,
//   getGridDateOperators,
// } from '@material-ui/data-grid';
import { 
  DataGrid, 
  getGridDateOperators 
} from '@mui/x-data-grid';
import { 
  makeStyles
} from '@material-ui/core/styles';
import {
  Button,
  Chip,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CheckBoxIcon                 from '@material-ui/icons/CheckBox';
import EditIcon                     from '@material-ui/icons/Edit';
import IndeterminateCheckBoxIcon    from '@material-ui/icons/IndeterminateCheckBox';
import DeleteIcon                   from '@material-ui/icons/Delete';
import { FontAwesomeIcon }  from '@fortawesome/react-fontawesome';
import { faRecycle }        from '@fortawesome/free-solid-svg-icons';

import BreadcrumbsNavigation      from '@frontend/components/BreadcrumbsNavigation';
import ConfirmationModal          from '@frontend/components/ConfirmationModal';
import Modal                      from '@frontend/components/Modal';
import TimespanSelection          from '@frontend/components/TimespanSelection';
import MachinesDropdownSelect        from '@frontend/modules/machine/MachinesDropdownSelect';
import useOrdersExportValidation  from '@frontend/modules/orders/hooks/useOrdersExportValidation';
import OrderExportActivationModal     from '@frontend/modules/orders/OrderExportActivationModal';
import OrderExportQualityReportModal  from '@frontend/modules/orders/OrderExportQualityReportModal';
import StructuresDropdownSelect   from '@frontend/modules/structure/StructuresDropdownSelect';
import { usePhrases }             from '@frontend/utils/usePhrases';
import useDateTimeFormat          from '@frontend/utils/useDateTimeFormat';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(0),
      },
    },
    orange: {
      color: theme.palette.primary.main,
    },
    selectField: {
      marginTop: theme.spacing(1.3),
      marginBottom: theme.spacing(1.1),
    },
    selectFieldMachine: {      
      marginTop: theme.spacing(1.3),
      marginBottom: theme.spacing(1.1),
      marginRight: theme.spacing(1),
      minWidth: 120,
      width: '100%',
      marginLeft: 'auto',     
    },
    paper: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    machinePulsesContainer: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      position: 'relative',
    },
    lastTableCell: {
      borderBottom: 'none',
    },
    pushDiv: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(9),
      },
      marginTop: theme.spacing(5),
    },
    topCardsContainer: {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    },
    cardCell: {
      padding: theme.spacing(1.5),
    },
    cardCellWithIcon: {
      padding: theme.spacing(1),
    },
    progress: {
      textAlign: 'center',
      margin: 'auto',
    },
    backdrop: {
      zIndex: 1,
      position: 'fixed',
      // left: 0,
      width: 'calc(100% - 56px)',
      textAlign: 'center',
    },
    button: { 
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1), 
    }
  }));
const OrdersExportValidationView = () => {

  const classes = useStyles();
  const phrases = usePhrases().phrases();
  const { structureId } = useParams();
  const {
      structure, 
      structures, 
      machines,
      handleChangeMachine, 
      handleChangeSubStructure,
      selectedSubStructure,
      selectedMachines,
      selectedActivations,
      errors,
      loadingOrders,
      reloadData,
      handleDeleteModalOpen,
      handleCloseConfirmationModal,
      handleEditActivation,
      handleDeleteActivation,
      handleAddActivationButton,
      modalOpen,
      handleCloseModal,
      qrModalOpen,
      handleCloseQRModal,
      handleOpenQRModal,
      activationToEdit,
      deleteModalOpen,
      handleUpdateTime
  } = useOrdersExportValidation(structureId);
  const { formatShortDate } = useDateTimeFormat();
  const columns = [
    { field: 'activationStatus', 
      headerName: phrases.modules.order_export.activationStatus, 
      flex: 1,
      valueGetter: (params) => (params.value ? phrases.modules.orders[params.value] : "-")},
    { 
      field: 'isSetup', 
      headerName: phrases.modules.order_export.isSetup, 
      flex: 1,
      renderCell: (params) => (params.value ? <CheckBoxIcon fontSize="small" />: <IndeterminateCheckBoxIcon fontSize='small'/>)
    },
    { 
      field: 'user', 
      headerName: phrases.modules.order_export.user, 
      flex: 1,
      valueGetter: (params) => (params.value.businessUserId ? params.value.businessUserId : params.value.email ? params.value.email: '-')
    },
    { field: 'handCount', headerName: phrases.modules.order_export.handCount, flex: 1 },
    { 
      field: 'partsProduced', 
      headerName: phrases.modules.order_export.partsProduced, 
      flex: 1,
      valueGetter: (params) => (params.value ?? 0)
    },
    { 
      field: 'totalScrap', 
      headerName: phrases.modules.order_export.totalScrap, 
      flex: 1,
      valueGetter: (params) => (params.value ?? 0)
    },
    {
      type: 'date',
      field: 'time',
      headerName: phrases.modules.order_export.startDate,
      flex: 1,
      valueGetter: (params) => (params.value ? formatShortDate(params.value, true) : '-'),
      filterOperators: getGridDateOperators()
        .map((operator) => {
          return {
            ...operator,
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null;
              }
              return ({ value }) => {
                switch (operator.value) {
                  case 'is': return moment(value).isSame(moment(filterItem.value));
                  case 'is not': return !moment(value).isSame(moment(filterItem.value));
                  case 'is after': return moment(value).isAfter(moment(filterItem.value));
                  case 'is on or after': return moment(value).isSameOrAfter(moment(filterItem.value));
                  case 'is before': return moment(value).isBefore(moment(filterItem.value));
                  case 'is on or before': return moment(value).isSameOrBefore(moment(filterItem.value));
                  default: return null;
                }
              };
            },
          };
        }),
    },
    {
      type: 'date',
      field: 'endTime',
      headerName: phrases.modules.order_export.endDate,
      flex: 1,
      valueGetter: (params) => (params.value == null ? "-": formatShortDate(moment(params.value), true)),
      filterOperators: getGridDateOperators()
        .map((operator) => {
          return {
            ...operator,
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null;
              }
              return ({ value }) => {
                switch (operator.value) {
                  case 'is': return moment(value).isSame(moment(filterItem.value));
                  case 'is not': return !moment(value).isSame(moment(filterItem.value));
                  case 'is after': return moment(value).isAfter(moment(filterItem.value));
                  case 'is on or after': return moment(value).isSameOrAfter(moment(filterItem.value));
                  case 'is before': return moment(value).isBefore(moment(filterItem.value));
                  case 'is on or before': return moment(value).isSameOrBefore(moment(filterItem.value));
                  default: return null;
                }
              };
            },
          };
        }),
    },
    {
      type: 'string',
      field: 'job',
      headerName: phrases.modules.order_export.job,
      flex: 1,
      renderCell: (params) => (
        <Link to={`/orders/job/${params.value.businessJobId}`} className={classes.orange}>
          {params.value.businessJobId ?? '-'}
        </Link>
      ),
    },
    {
      type: 'string',
      field: 'machine',
      headerName: phrases.modules.order_export.machine,
      flex: 1,
      renderCell: (params) => (
        <Link to={`/machine-overview/${params.value.id}`} className={classes.orange}>
          <Chip color="primary" key={params.value.id} className={classes.chip} label={params.value.name} />
        </Link>
      )
    },
    {
      type: 'string',
      headerName: phrases.tables.shared.actions,
      field: 'id',
      flex: 1,
      renderCell: (params) => (
          <div>
            <Tooltip title={phrases.modules.order_export.editActivation}>
              <IconButton
                aria-label="edit icon for current row"
                aria-haspopup="true"
                color="primary"
                size="small"
                onClick={() => handleEditActivation(params.row)}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={phrases.modules.order_export.deleteActivation}>
              <IconButton
                aria-label="delete icon for current row"
                aria-haspopup="true"
                color="primary"
                size="small"
                onClick={() => handleDeleteModalOpen(params.row)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>            
            <Tooltip title={phrases.modules.order_export.qualityReports}>
              <IconButton
                aria-label="edit icon for current row"
                aria-haspopup="true"
                color="primary"
                size="small"
                onClick={() => handleOpenQRModal(params.row)}
              >
                <FontAwesomeIcon
                  icon={faRecycle}
                  size="1x"
                />
              </IconButton>
            </Tooltip>
          </div>
        ),
      
    }
  ];
    return ( <>
        <div className={classes.root}>
            <Grid container spacing={2} className={classes.root}>
                <Hidden xsDown>
                <Grid item xs={12}>
                    <BreadcrumbsNavigation selectedStructure={structure} />
                </Grid>
                </Hidden>
                <Grid 
                    container 
                    spacing={2} 
                    className={classes.topCardsContainer}
                    variant="outlined"
                >
                    <Grid item md={6}>
                        <Paper variant="outlined" square className={classes.paper}>                            
                            <StructuresDropdownSelect
                                options={structures}
                                label={phrases.modules.order_export.selectStructure}
                                onChange={handleChangeSubStructure}
                                value={selectedSubStructure}
                                error={errors.structure}
                                helperText={errors.structure}
                            />
                        </Paper>
                    </Grid>
                    <Grid item md={6}>
                        <Paper variant="outlined" square className={classes.paper}>
                            {/* <div className={classes.selectFieldMachine}> */}
                              <MachinesDropdownSelect
                                  label={phrases.modules.order_export.selectMachine}
                                  options={machines}
                                  onChange={handleChangeMachine}
                                  value={selectedMachines}
                                  error={errors.machines}
                                  helperText={errors.machines}
                                  required
                              />
                            {/* </div> */}
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="button" display="block" color="textSecondary">
                        {phrases.modules.order_export.activations}
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Paper variant="outlined" square>
                        <Button
                          color="primary"
                          size="small"
                          className={classes.button}
                          variant="contained"
                          aria-label="select merge strategy"
                          onClick={(e) => handleAddActivationButton()}
                          disabled={selectedMachines.length === 0}
                        >
                          {phrases.modules.order_export.addActivation}
                        </Button>
                        <DataGrid
                          autoHeight
                          rows={selectedActivations}
                          columns={columns}
                          onRowDoubleClick={(row) => handleEditActivation(row.row)}
                          pageSize={10}
                          disableColumnSelector
                          disableColumnMenu
                          disableSelectionOnClick
                          loading={loadingOrders}
                          sx={{
                            ".MuiDataGrid-iconButtonContainer": {
                              visibility: 'hidden !important',
                              opacity: '0 !important',
                            },
                            ".MuiDataGrid-sortIcon":{
                              visibility: 'hidden !important',
                              opacity: '0 !important',
                              transition: 'none !important',
                            }

                          }}
                        />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </div>
        <Modal
          modalTitle={!_.isEmpty(activationToEdit) ? phrases.modules.order_export.editActivationModalTitle: phrases.modules.order_export.addActivationModalTitle}
          open={modalOpen}
          handleClose={handleCloseModal}
          content={(
            <OrderExportActivationModal
              isEdit
              handleCloseModal={handleCloseModal}
              activationToEdit={activationToEdit}
              machines={selectedMachines}
              reloadData={reloadData}
            />
          )}
        />
        <Modal
          modalTitle={phrases.modules.order_export.qualityReportsList}
          open={qrModalOpen}
          handleClose={handleCloseQRModal}
          content={(
            <OrderExportQualityReportModal              
              handleCloseModal={handleCloseQRModal}
              activation={activationToEdit}
            />
          )}
        />
        <ConfirmationModal
          title={phrases.modules.order_export.deleteActivationModalTitle}
          text={phrases.modules.order_export.deleteActivationModalText}
          openModal={deleteModalOpen}
          handleClose={handleCloseConfirmationModal}
          callback={() => handleDeleteActivation()}
        />
        <TimespanSelection
          onTimespanSelect={handleUpdateTime}
          disabled={false}
        />
    </> );
}
 
export default OrdersExportValidationView;